import React from 'react';

import {
  CallDoneContextInterface,
  useCallDoneHook,
} from './call-done.hook';

const CallDoneContext = React.createContext<CallDoneContextInterface | undefined>(undefined);

export const useCallDoneContext = () => {
  const context = React.useContext(CallDoneContext);

  if (context === undefined) {
    throw new Error('useCallDoneContext must be used within CallDoneProvider');
  }

  return context;
}

export const CallDoneProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const api = useCallDoneHook();

  return (
    <CallDoneContext.Provider value={api}>
      {children}
    </CallDoneContext.Provider>
  );
};
