import {
  useMainContext,
  QueueAddProvider,
  CallDoneProvider,
} from '../../context';

import { CardDataComposition } from '@app/agui/compositions';
import { PageBaseLayout } from '@app/components/layouts';

import { QueueAddDialogComponent } from '../queue-add-dialog';
import { CallComponent } from '../call';
import { CallDoneComponent } from '../call-done';


export const MainComponent = () => {
  const {
    callStatus,
  } = useMainContext();

  return (
    <>
      {(callStatus === 'idle') && (
        <QueueAddProvider>
          <QueueAddDialogComponent />
        </QueueAddProvider>
      )}

      {(callStatus === 'pending' || callStatus === 'call') && (
        <CallComponent />
      )}

      {callStatus === 'done' && (
        <CallDoneProvider>
          <CallDoneComponent />
        </CallDoneProvider>
      )}
    </>
  );
};
