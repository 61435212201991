import { useMainContext } from '../../context';

export const ConsultantVideoComponent = () => {
  const {
    provideMediaRef,
  } = useMainContext();

  return (
    <video
      id="consultant-video"
      width='100%'
      autoPlay
      playsInline
      muted={true}
      style={{ display: 'block', marginBottom: '0' }}
      ref={(node) => {
        provideMediaRef('local', node);
      }}
      // hidden={cameraMuted}
    />
  );
};
