import React from 'react';

import { ChangeFieldEventType } from '@app/types';

import { useMainContext } from '../main';
import { useAppStore } from '@app/stores';
import { CallApi } from '@app/api';


const initialFormValue = {
  CP_noAudioOperator: false,
  CP_noVideoOperator: false,
  CP_noAudioCaller: false,
  CP_noVideoCaller: false,
};

export interface CallDoneContextInterface {
  formValue: typeof initialFormValue;
  formDirty: boolean;
  formIsSubmitting: boolean,
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: () => void;
};

export const useCallDoneHook = (
): CallDoneContextInterface => {
  const { notifyCall } = useAppStore();
  const {
    callUuid,
    goToHome,
  } = useMainContext();
  const [ formValue, formValueSet ] = React.useState(initialFormValue);
  const [ formDirty, formDirtySet ] = React.useState(false);
  const [ formIsSubmitting, formIsSubmittingSet ] = React.useState(false);
  
  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const formReset = React.useCallback(() => {
    formValueSet(initialFormValue);
    formDirtySet(false);
    formIsSubmittingSet(false);
  }, []);

  const onSubmit = React.useCallback(async () => {
    if (callUuid === null) return;

    formIsSubmittingSet(true);

    const response = await CallApi.feedbackFromCaller({
      callUuid,
      ...formValue,
    });

    formIsSubmittingSet(false);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Ошибка записи',
      });
    }

    goToHome();
    formReset();
  }, [
    callUuid,
    formValue,
    goToHome,
    notifyCall,
    formReset,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
  ]);
};
