import { ACTIONS_CONSULTATIONS, consultationSocket } from '@app/api';
import React from 'react';
import { useParams } from 'react-router-dom';


export interface MainContextInterface {
  consultationUuid: string | undefined;
  offerSet: (e: { offer: RTCSessionDescriptionInit; }) => void;
  provideMediaRef: (id: string, node: HTMLVideoElement | null) => void;
  iceCandidateSet: (event: { iceCandidate: RTCIceCandidate }) => void;
  consultationStart: (e: { consultantClientId: string; }) => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { uuid: consultationUuid } = useParams();

  const [ consultantClientId, consultantClientIdSet ] = React.useState<string | null>(null);

  const peerConnection = React.useRef<RTCPeerConnection | null>(null);
  const localMediaStream = React.useRef<MediaStream | null>(null);
  const peerMediaElements = React.useRef<{ [key: string]: HTMLVideoElement | null }>({});

  const provideMediaRef = React.useCallback((id: string, node: HTMLVideoElement | null) => {
    peerMediaElements.current[id] = node;
  }, []);

  const mediaStart = React.useCallback(async () => {
    localMediaStream.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        width: 1280,
        height: 720,
      },
    });

    const localVideoElement = peerMediaElements.current.local;
    if (localVideoElement) {
      localVideoElement.volume = 0;
      localVideoElement.srcObject = localMediaStream.current;
    }

    consultationSocket.emit(
      ACTIONS_CONSULTATIONS.CALLER_CONNECT,
      { consultationUuid },
    );
  }, [
    consultationUuid,
  ]);

  const consultationStart = React.useCallback((e: { consultantClientId: string; }) => {
    const { consultantClientId } = e;
    
    consultantClientIdSet(consultantClientId);
    
    if (localMediaStream.current === null) return console.log('consultationStart - localMediaStream is null');

    peerConnection.current = new RTCPeerConnection({
      iceServers: [{
        urls: ['stun:80.90.189.135:3478']
      }],
    });

    try {
      for (const track of localMediaStream.current.getTracks()) {
        peerConnection.current.addTrack(track, localMediaStream.current);
      }
    } catch (error) {
      console.error(error);      
    }

    peerConnection.current.ontrack = ((event: RTCTrackEvent) => {
      event.track.onunmute = () => {
        // if (peerMediaElements.current['remote'] && peerMediaElements.current['remote'].srcObject) {
        //   return;
        // }

        peerMediaElements.current['remote']!.srcObject = event.streams[0];
      }
    });

    peerConnection.current.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      consultationSocket.emit(ACTIONS_CONSULTATIONS.RELAY_ICE, {
        iceCandidate: event.candidate,
        clientId: consultantClientId,
      });
    };
  }, []);

  const offerSet = React.useCallback(async (e: { offer: RTCSessionDescriptionInit; }) => {
    if (peerConnection.current === null) return console.log('offerSet - peerConnection is null');

    await peerConnection.current.setRemoteDescription(
      new RTCSessionDescription(e.offer),
    );

    const answer = await peerConnection.current.createAnswer();
    await peerConnection.current.setLocalDescription(answer);

    consultationSocket.emit(ACTIONS_CONSULTATIONS.CALLER_SEND_ANSWER, {
      consultationUuid,
      answer,
    });
  }, [
    consultationUuid,
  ]);

  const iceCandidateSet = React.useCallback((event: { iceCandidate: RTCIceCandidate }) => {
    if (!peerConnection.current || !peerConnection.current.remoteDescription) return;

    try {
      peerConnection.current!.addIceCandidate(event.iceCandidate);
    } catch (error) {
      console.log(error);
    }
  }, []);

  React.useEffect(() => {
    mediaStart();

    return () => {
      consultationSocket.emit(ACTIONS_CONSULTATIONS.CALLER_DISCONNECT, { consultationUuid });
    }
  }, [
    mediaStart,
    consultationUuid,
  ]);

  return React.useMemo(() => ({
    consultationUuid,
    offerSet,
    provideMediaRef,
    iceCandidateSet,
    consultationStart,
  }), [
    consultationUuid,
    offerSet,
    provideMediaRef,
    iceCandidateSet,
    consultationStart,
  ]);
};
