import React from 'react';

import {
  consultationSocket,
  ACTIONS_CONSULTATIONS,
} from '@app/api';

import { useMainContext } from '../main';


export interface ConnectionContextInterface {
};

export const useConnectionHook = (
): ConnectionContextInterface => {
  const {
    consultationUuid,
    offerSet,
    iceCandidateSet,
    consultationStart,
  } = useMainContext();
  // const {
  //   generalDataSet,
  //   personalDataSet,
  //   callRun,
  //   offerSet,
  //   iceCandidateSet,
  //   operatorCallStop,
  //   operatorCallRejected,
  //   clientDisconnect,
  // } = useMainContext();

  // React.useEffect(() => {
  //   socket.on(ACTIONS.OFFER_TO_CALLER_SEND, offerSet);
  //   return () => {socket.off(ACTIONS.OFFER_TO_CALLER_SEND, offerSet);};
  // }, [ offerSet ]);

  // React.useEffect(() => {
  //   socket.on(ACTIONS.OPERATOR_CALL_START, callRun);
  //   return () => {socket.off(ACTIONS.OPERATOR_CALL_START, callRun)};
  // }, [ callRun ]);

  // React.useEffect(() => {
  //   socket.on(ACTIONS.OPERATOR_CALL_STOP, operatorCallStop);
  //   return () => {socket.off(ACTIONS.OPERATOR_CALL_STOP, operatorCallStop)};
  // }, [ operatorCallStop ]);

  // React.useEffect(() => {
  //   socket.on(ACTIONS.OPERATOR_CALL_REJECT, operatorCallRejected);
  //   return () => {socket.off(ACTIONS.OPERATOR_CALL_REJECT, operatorCallRejected)};
  // }, [ operatorCallRejected ]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.CALLER_READY, consultationStart);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.CALLER_READY, consultationStart)};
  }, [consultationStart]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.ICE_CANDIDATE, iceCandidateSet);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.ICE_CANDIDATE, iceCandidateSet);};
  }, [ iceCandidateSet ]);

  React.useEffect(() => {
    consultationSocket.on(ACTIONS_CONSULTATIONS.OFFER_TO_CALLER_SEND, offerSet);
    return () => {consultationSocket.off(ACTIONS_CONSULTATIONS.OFFER_TO_CALLER_SEND, offerSet)};
  }, [offerSet]);
  
  return React.useMemo(() => ({
  }), [
  ]);
};
