export const ACTIONS_CONSULTATIONS = {
  // EMIT
  CALLER_CONNECT: 'CALLER_CONNECT',
  CALLER_DISCONNECT: 'CALLER_DISCONNECT',
  RELAY_ICE: 'RELAY_ICE',

  // LISTEN
  OFFER_TO_CALLER_SEND: 'OFFER_TO_CALLER_SEND',
  CALLER_READY: 'CALLER_READY',



  
  ///////

  // EMIT
  CALLER_SEND_ANSWER: 'CALLER_SEND_ANSWER',


  // EMIT
  ANSWER_TO_CONSULTANT_SEND: 'ANSWER_TO_CONSULTANT_SEND',

  ICE_CANDIDATE: 'ICE_CANDIDATE',
};