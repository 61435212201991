import { useMainContext } from '../../context';

import styles from './caller-video.module.scss';


export const CallerVideoComponent = () => {
  const {
    provideMediaRef,
  } = useMainContext();

  return (
    <div className={styles['caller-video']}>
      <video
        id="caller-video"
        width='100%'
        autoPlay
        playsInline
        ref={(node) => {
          provideMediaRef('remote', node);
        }}
      />
    </div>
  );
};
