import { HttpService } from '@app/services';
import { BaseResponseInterface } from '@app/types';

import {
  CallFeedbackFromCallerRequest,
  CallFeedbackFromCallerResponse,
} from './types';


export class CallApi {
  public static async feedbackFromCaller(
    payload: CallFeedbackFromCallerRequest,
  ): Promise<BaseResponseInterface<CallFeedbackFromCallerResponse>> {
    return await HttpService.sendRequest('POST', '/calls/feedback-from-caller', payload);
  };
};
