import {
  PageBaseLayout,
  CardDataComposition,
  TypographyBrick,
  CardContentBrick,
  FieldBooleanCheckBlock,
  CardActionsBrick,
  DividerHorizontalBrick,
  ButtonLoadingBrick,
} from '@app/components';
import { useCallDoneContext } from '../../context';


export const CallDoneComponent = () => {
  const {
    formValue,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
  } = useCallDoneContext();

  return (
    <PageBaseLayout>
      <CardDataComposition
        title="Звонок завершен"
      >
        <CardContentBrick>
          <TypographyBrick variant="body1">Спасибо за обращение. Вы всегда можете обратиться снова.</TypographyBrick>
        </CardContentBrick>
        <CardContentBrick>
          <TypographyBrick variant="body1">Были ли проблемы со связью?</TypographyBrick>
          <FieldBooleanCheckBlock
            name="CP_noAudioOperator"
            value={formValue.CP_noAudioOperator}
            label="Не было слышно оператора"
            onChange={changeFormValue}
            hideError
          />
          <FieldBooleanCheckBlock
            name="CP_noVideoOperator"
            value={formValue.CP_noVideoOperator}
            label="Не было видео изображения от оператора"
            onChange={changeFormValue}
            hideError
          />
          <FieldBooleanCheckBlock
            name="CP_noAudioCaller"
            value={formValue.CP_noAudioCaller}
            label="Оператор не слышал меня"
            onChange={changeFormValue}
            hideError
          />
          <FieldBooleanCheckBlock
            name="CP_noVideoCaller"
            value={formValue.CP_noVideoCaller}
            label="Оператор не видел меня"
            onChange={changeFormValue}
            hideError
          />
        </CardContentBrick>
        <DividerHorizontalBrick />
        <CardActionsBrick>
          <ButtonLoadingBrick
            onClick={onSubmit}
            variant="contained"
            loading={formIsSubmitting}
          >
            Хорошо
          </ButtonLoadingBrick>
        </CardActionsBrick>
      </CardDataComposition>
    </PageBaseLayout>
  );
};
