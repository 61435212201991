import { TransitionOpacityBrick } from '@app/agui/bricks';

import styles from './main.module.scss';
import { ConsultantVideoComponent } from '../consultant-video';
import { CallerVideoComponent } from '../caller-video';


export const MainComponent = () => {
  return (
    <TransitionOpacityBrick>
      <div className={styles['main']} id="call-wrapper" >
        <div className={styles['main__consultant-video']}>
          <ConsultantVideoComponent />
        </div>
        <div className={styles['main__caller-video']}>
          <CallerVideoComponent />
        </div>
        {/* <div className={styles['main__controls']}>
          <ControlsComponent />
        </div> */}
      </div>
    </TransitionOpacityBrick>
  );
};
