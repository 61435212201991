export const ACTIONS_CALL = {
  // LISTEN
  DATA_GENERAL_SEND: 'DATA_GENERAL_SEND',
  DATA_TO_CALLER_SEND: 'DATA_TO_CALLER_SEND',
  OFFER_TO_CALLER_SEND: 'OFFER_TO_CALLER_SEND',
  OPERATOR_CALL_START: 'OPERATOR_CALL_START',
  OPERATOR_CALL_STOP: 'OPERATOR_CALL_STOP',
  OPERATOR_CALL_REJECT: 'OPERATOR_CALL_REJECT',
  ICE_CANDIDATE: 'ICE_CANDIDATE',
  CLIENT_DISCONNECT: 'CLIENT_DISCONNECT',

  // EMIT
  CALLER_CONNECT: 'CALLER_CONNECT',
  CALLER_SEND_ANSWER: 'CALLER_SEND_ANSWER',
  CALLER_CALL_STOP: 'CALLER_CALL_STOP',
  RELAY_ICE: 'RELAY_ICE',
};
